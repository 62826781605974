/*
* ==========================================================
*     LOGIN PAGE
* ==========================================================
*/
.login-page {
  position: relative;
}

.login-page label {
  margin-bottom: 0;
}

.login-page::before {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  /* background: #008598; */
  background: #008598; /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, #008598 0%, #004154 100%); /* FF3.6-15 */
  background:
    -webkit-radial-gradient(
      center,
      ellipse cover,
      #008598 0%,
      #004154 100%
    ); /* Chrome10-25,Safari5.1-6 */

  background:
    radial-gradient(
      ellipse at center,
      #008598 0%,
      #004154 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#008598', endColorstr='#004154', GradientType=1); /* IE6-9 fallback on horizontal gradient */
  overflow-x: hidden;
}

.login-page .container {
  min-height: 100vh;
  z-index: 999;
  padding: 20px;
  position: relative;
}

.login-page .form-holder {
  width: 100%;
  margin-bottom: 50px;
}

.login-page .form-holder .login-form {
  border-radius: 5px;
}

.login-page .form-holder .info,
.login-page .form-holder .form {
  padding: 40px;
  height: 100%;
}

.login-page .form-holder div[class*='col-'] {
  padding: 0;
}

.login-page .logo {
  width: 500px;
  height: 100%;
  background-size: contain;
  background-image: url('sonar-logo.png');
  background-position: left center;
  background-repeat: no-repeat;
}

.login-page .form-holder .info h1 {
  font-size: 2.5em;
  font-weight: 600;
}

.login-page .form-holder .info p {
  font-weight: 300;
}

.login-page .form-holder .form .form-group {
  position: relative;
  margin-bottom: 10px;
}

.login-page .form-holder .form .content {
  width: 100%;
}

.login-page .form-holder .form form {
  width: 100%;
  max-width: 500px;
}

.login-page .validation-message {
  height: 40px;
  padding: 0;
}

.login-page .validation-message .alert {
  padding: 2px 10px;
  font-size: 0.8em;
}

.login-page .form-holder .form #login,
.login-page .form-holder .form #register {
  margin-bottom: 20px;
  cursor: pointer;
}

.login-page .form-holder .form label {
  color: #7a7a7a;
}

.login-page small {
  color: #4d4d4d;
}

.login-page small a {
  color: #008598;
}

.login-page .form-holder .form .terms-conditions label {
  cursor: pointer;
  color: #aaa;
  font-size: 0.9em;
}

.login-page .copyrights {
  width: 100%;
  z-index: 9999;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
}

.form-group .label-material i:hover {
  cursor: pointer;
}

.row.row-grid-top [class*='col-'] + [class*='col-'] {
  margin-top: 30px;
}
