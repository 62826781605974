.toast-failure {
  height: 100%;
  background-color: #f00;
  font-size: 1.1em;
}

.toast-success {
  height: 100%;
  background-color: #4bb543;
  font-size: 1.1em;
}

.toast-info {
  height: 100%;
  background-color: #008598;
  font-size: 1.1em;
}
