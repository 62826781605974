.contactDetail-heatmap {
  width: 100%;
  height: 100%;
}

.contactDetail-container {
  height: 105%;
}

@media (max-height: 700px) {
  .contactDetail-container {
    height: 200%;
  }
}
