.searchResults {
  z-index: 2000 !important;
  background-color: #30333d;
  color: whitesmoke;
  position: fixed;
  width: 22.5vw;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 1.5vh;
}

.searchTitle {
  font-weight: 500;
}

.search-result-clickable {
  cursor: pointer;
}

.searchInputBox {
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  padding: 10px 20px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #008598;
  -o-text-overflow: clip;
  text-overflow: clip;
  -webkit-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  width: 100%;
  border: 1px solid #ccc;
}

.searchHeader {
  width: 15vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.searchFilters {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  color: whitesmoke;
}

#seeAllResults {
  margin-top: 1vh;
  text-align: right;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: stretch;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .mobile-search {
    height: 95vh;
    width: 100vw;
    margin: 0 auto;
    z-index: 100;
    position: absolute;
    left: 10%;
    top: 55px;
    background-color: #30333d;
  }

  .searchResults {
    z-index: 100;
    background-color: #30333d;
    color: whitesmoke;
    position: fixed;
    width: 80vw;
    margin-left: 5vw;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    padding: 1.5vh;
  }

  .searchHeader {
    margin-top: 2.5vh;
    width: 80vw;
    margin-left: 5vw;
  }

  .searchFilters {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    color: whitesmoke;
  }

  #seeAllResults {
    margin-top: 1vh;
    text-align: right;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: stretch;
  }
}
