.appSwitcher .carrat {
  cursor: pointer;
  vertical-align: middle;
  padding-right: 10px;
}

.appSwitcher .text {
  padding-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.appSwitcher {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: stretch;
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .appSwitcher .text {
    max-width: 150px;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .appSwitcher {
    margin-top: 3px;
  }

  .appSwitcher .text {
    max-width: 100px;
  }
}
