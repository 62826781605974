#dashboard-primary {
  height: 100%;
  padding: 0 20px;
  text-align: center;
}

#dashboard-top-container {
  height: 45%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 2.5%;
}

#dashboard-middle-container {
  height: 35%;
  background-color: #fff;
  padding: 0 2.5%;
}

#dashboard-bottom-container {
  height: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 2.5%;
}

#dasboard-middle-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#fa-7x {
  font-size: 7.5em;
  color: #008598;
}

#dash-logo {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
}

#dash-logo > img {
  width: 30%;
  height: auto;
}

#dash-top-sum {
  font-size: 1.5em;
  font-weight: 600;
  padding-bottom: 1.25%;
  color: #121212;
}

#dash-top-body {
  font-size: 1.25em;
  padding: 0 15%;
  font-weight: 500;
  color: #121212;
}

#dash-cta {
  font-size: 1.25em;
  color: #008598;
  font-weight: bold;
}

#dash-cta-text {
  font-size: 1em;
  font-weight: 500;
  padding: 2% 10%;
}

#dash-bot-head {
  font-size: 1.5em;
  font-weight: 500;
  padding-bottom: 5%;
}

#dash-bot-text {
  font-size: 1.25em;
  font-weight: bold;
  margin-left: 2em;
  margin-right: 2em;
  color: #008598;
}

div.row a#isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}

.topRowDashboard {
  display: flex;
  flex-direction: row;
  /* flex-wrap: nowrap; */
  justify-content: space-around;
  align-items: center;
  align-content: stretch;
}

.gatewaysDashboard {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.gatewaysDashboardStatus {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.dashboardRowApart {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}

.secondRowDashboard {
  margin-top: 40px;
}

.summaryCard {
  width: 100%;
  border-top: 1px solid rgba(9, 30, 66, 0.25);
}

.summaryCard-l {
  border-right: 0.5px solid rgba(9, 30, 66, 0.25);
}

.summaryCard-r {
  border-left: 0.5px solid rgba(9, 30, 66, 0.25);
}

.summaryCard:hover {
  cursor: pointer;
}
