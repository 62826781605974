nav.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #414042;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

nav.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

nav.sidenav a:hover {
  color: #f1f1f1;
}

nav.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* stylelint-disable */
/* #region Mouldifi template styles */
ul.nav-menu {
  position: relative;
  font-size: 14px;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
ul.nav-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
ul.nav-menu {
  -ms-overflow-style: none;
}

ul.nav-menu li {
  position: relative;
  margin-bottom: 1px;
}

.nav-header {
  text-align: center;
}

ul.nav-menu li.menu + .nav-header {
  margin-top: 40px;
}

ul.nav-menu li.menu .sub-menu {
  display: none;
}

ul.nav-menu li.menu.open > .sub-menu {
  display: block;
}

ul.nav-menu li.menu.open .menu.active > .sub-menu {
  display: block;
}

ul.nav-menu li a {
  color: #ffffff;
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none;
  border-radius: 0;
}

ul.nav-menu li a .badge {
  margin-bottom: 0;
  padding: 3px 8px 2px;
}

ul.nav-menu .nav-header:first-child {
  margin-top: 30px;
}

ul.nav-menu .nav-header {
  color: #808080;
  padding: 4px 22px;
  font-size: 11px;
  text-transform: uppercase;
}

ul.nav-menu > li.selected > a,
ul.nav-menu > li.open > a,
ul.nav-menu > li > a:hover,
/* ul.nav-menu > li > a:focus {
    color: #3f51b5;
} */
ul.nav-menu > li:focus {
  background-color: rgba(255, 255, 255, 0.08);
}

ul.nav-menu li.menu > a {
  padding: 16px 20px;
  border-left: 2px solid transparent;
}

ul.nav-menu li.menu.open > a {
  border-color: transparent;
}

ul.nav-menu li.menu > a:focus::before,
ul.nav-menu li.menu > a:hover::before {
  color: #3f51b5;
}

ul.nav-menu li i {
  margin-right: 12px;
  font-size: 15px;
}

ul.nav-menu li ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

ul.nav-menu li.menu .sub-menu li > a {
  padding: 7px 20px 7px 56px;
}

ul.nav-menu li.menu ul.sub-menu > li.menu > ul.sub-menu > li.menu > a {
  padding-left: 76px;
}

ul.nav-menu li.menu ul.sub-menu > li.menu > ul.sub-menu > li.menu > ul.sub-menu > li.menu > a {
  padding-left: 96px;
}

ul.nav-menu
  li.menu
  ul.sub-menu
  > li.menu
  > ul.sub-menu
  > li.menu
  > ul.sub-menu
  > li.menu
  > ul.sub-menu
  > li
  > a {
  padding-left: 116px;
}

ul.nav-menu li.menu > a::before {
  color: #808080;
  content: '\f2fb';
  display: inline-block;
  float: right;
  font-family: 'Material-Design-Iconic-Font';
  font-size: 15px;
  margin-left: 10px;
  position: relative;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  font-weight: normal;
}

ul.nav-menu li.menu.no-arrow > a::before {
  display: none;
}

ul.nav-menu li.menu.selected > a::before,
ul.nav-menu li.menu.open > a::before,
ul.nav-menu li.menu > a:focus::before,
ul.nav-menu li.menu li > a:hover::before {
  background-color: transparent;
  color: #3f51b5;
}

ul.nav-menu li.menu.open > a::before,
ul.nav-menu li.menu.active > a::before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

ul.nav-menu li.menu .sub-menu li.active > a .nav-text {
  padding: 3px 7px;
  margin-left: -7px;
  background-color: #3f51b5;
  color: #fff;
  border-radius: 4px;
}

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li.active > a::before,
ul.nav-menu li.menu .sub-menu li > a:focus::before,
ul.nav-menu li.menu .sub-menu li > a:hover::before {
  color: #3f51b5;
}
/* #endregion Mouldifi template styles */
/* stylelint-enable */
