.layout-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: margin-left 0.5s;
  padding: 16px;
  overflow: auto;
}

.layout-main-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--nav-height));
  width: 100%;
}

.layout-inner-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 1.5em;
  height: 95%; /* Leave room for footer */
}

.layout-progressbar {
  transition: height 1s;
  transition-delay: 0.1s;
}
