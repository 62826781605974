/* stylelint-disable declaration-colon-newline-after, value-list-comma-newline-after */
:root {
  --nav-height: 50px;
}

.header {
  background-color: #fafafb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.05),
    0 2px 8px rgba(0, 0, 0, 0.05);
}

.header-left {
  height: var(--nav-height);
}
