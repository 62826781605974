.media-player-bar {
  max-width: 35vw;
  margin: 0 0 16px 0;
  min-height: 10px;
  height: 10px;
  width: 35vw;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #007bff;
}
