footer.t-footer {
  position: absolute;
  bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 20px;
  padding-right: 2em;
}
