.dashboard-card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 150px;
  max-width: 200px;
  min-height: 150px;
  box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.31) 0 0 1px 0;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
}

.dashboard-card-title {
  font-size: 1.125em;
  text-align: left;
  font-weight: bold;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
}

.dashboard-card-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-end;
  flex-grow: 1;
  width: 100%;
  height: auto;
  position: relative;
}
